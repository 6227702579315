import moment from "moment";
// 时间转换
const changeTime = (string) => {
  // 时间字符传 2019/10/01T12:00:00+08:00(后面时区固定为+08:00)
  let time = moment(string).format();
  let newTime = time.slice(0, 19) + "+08:00";
  //let newTime = moment(string).format('YYYY-MM-DD h:mm:ss')
  // 时间戳
  //let time = new Date(string)
  //let newTime = Date.parse(time) / 1000
  return newTime;
};

const changeDate = (time) => {
  if (!time || time === "未知") {
    return "未知";
  }
  let date = moment.utc(time).utcOffset(480).format("YYYY-MM-DD HH:mm:ss");
  return date;
};
// number 千位分隔符
const numFormat = (num) => {
  num = num.toString().split("."); // 分隔小数点
  var arr = num[0].split("").reverse(); // 转换成字符数组并且倒序排列
  var res = [];
  for (var i = 0, len = arr.length; i < len; i++) {
    if (i % 3 === 0 && i !== 0) {
      res.push(","); // 添加分隔符
    }
    res.push(arr[i]);
  }
  res.reverse(); // 再次倒序成为正确的顺序
  if (num[1]) {
    // 如果有小数的话添加小数部分
    res = res.join("").concat("." + num[1]);
  } else {
    res = res.join("");
  }
  return res;
};

// 将秒转换为时分秒
const changeSecond = (ms) => {
  let hour = Math.floor(ms / 3600);
  let min = Math.floor((ms % 3600) / 60);
  let second = Math.floor((ms % 3600) % 60);
  hour = hour > 0 ? (hour <= 9 ? "0" + hour : hour) : "00";
  min = min > 0 ? (min <= 9 ? "0" + min : min) : "00";
  second = second > 0 ? (second <= 9 ? "0" + second : second) : "00";
  return hour + ":" + min + ":" + second;
};

// 保留两位小数
const transformFloat = (init) => {
  return `${Number(init).toFixed(2)}`;
};
//深拷贝
const deepCopy = function (data, hash = new WeakMap()) {
  if (typeof data !== "object" || data === null) {
    throw new TypeError("传入参数不是对象");
  }
  // 判断传入的待拷贝对象的引用是否存在于hash中
  if (hash.has(data)) {
    return hash.get(data);
  }
  let newData = {};
  const dataKeys = Object.keys(data);
  dataKeys.forEach((value) => {
    const currentDataValue = data[value];
    // 基本数据类型的值和函数直接赋值拷贝
    if (typeof currentDataValue !== "object" || currentDataValue === null) {
      newData[value] = currentDataValue;
    } else if (Array.isArray(currentDataValue)) {
      // 实现数组的深拷贝
      newData[value] = [...currentDataValue];
    } else if (currentDataValue instanceof Set) {
      // 实现set数据的深拷贝
      newData[value] = new Set([...currentDataValue]);
    } else if (currentDataValue instanceof Map) {
      // 实现map数据的深拷贝
      newData[value] = new Map([...currentDataValue]);
    } else {
      // 将这个待拷贝对象的引用存于hash中
      hash.set(data, data);
      // 普通对象则递归赋值
      newData[value] = deepCopy(currentDataValue, hash);
    }
  });
  return newData;
};
// 将秒转化为时分秒
const getSFM = function (seconds, dateFormat = "H:i:s") {
  if (!seconds) return "00:00:00";
  var obj = {};
  obj.H = Number.parseInt(seconds / 3600);
  obj.i = Number.parseInt((seconds - obj.H * 3600) / 60);
  obj.s = Number.parseInt(seconds - obj.H * 3600 - obj.i * 60);
  if (obj.H < 10) {
    obj.H = "0" + obj.H;
  }
  if (obj.i < 10) {
    obj.i = "0" + obj.i;
  }
  if (obj.s < 10) {
    obj.s = "0" + obj.s;
  }

  // 3.解析
  var rs = dateFormat
    .replace("H", obj.H)
    .replace("i", obj.i)
    .replace("s", obj.s);
  return rs;
};

// 修改文件大小
const FileSize = (sizes) => {
  let mYsize = sizes;
  if (mYsize == 0) {
    return 0 + "B";
  } else {
    //转换为GB
    mYsize = parseFloat((mYsize / 1024 / 1024).toFixed(2)) + "MB";
  }
  return mYsize;
};
// 修改日期格式
const changDataTime = (data) => {
  const time = data * 1000;
  return new Date(time).toLocaleString();
};

export default {
  changeDate,
  numFormat,
  changeSecond,
  transformFloat,
  deepCopy,
  changeTime,
  getSFM,
  FileSize,
  changDataTime,
};
